import React, { Component } from "react";

class Brand extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/preztel/teatro-calima.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/preztel/alltic.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/preztel/rivera-robles.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/preztel/tecal.png" alt="Logo Images"/>
                    </li>
                    
                </ul>
            </React.Fragment>
        )
    }
}
export default Brand;