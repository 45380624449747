import React, { Component } from "react";

class About extends Component{
    render(){
        let title = '¿Quiénes somos?',
        description = 'Somos una empresa formada por ingenieros electrónicos y de telecomunicaciones cuyo principal objetivo es ayudar a nuestros clientes a desarrollar sus ideas desde un enfoque tecnológico y moderno.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/preztel/preztel-about.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">¿Cómo nacimos?</h3>
                                                <p>Nuestra empresa nació indirectamente en la Universidad Autónoma de Occidente donde realizabamos proyectos académicos propios y de terceros.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            
                                            <div className="about-us-list">
                                                <h3 className="title">¿Qué hacemos?</h3>
                                                <p>Nuestra experiencia va desde la creación de páginas web hasta el desarrollo de plataformas IoT, pasando por el diseño e instalación de redes de telecomunicaciones.</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;