import React, { Component, Fragment } from "react";

//Components
import Portfolio from "../components/portfolio/Portfolio";
import SliderFour from "../components/slider/Slider";
import Service from "../components/service/Service";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import About from "../components/about/About";
import Brand from "../components/brand/Brand";

import Team from "../components/team/Team";

import ScrollToTop from "react-scroll-up";

//Icons
import { FiHeadphones, FiMail, FiMapPin, FiChevronUp } from "react-icons/fi";

class Preztel extends Component {
  render() {
    //const PostList = BlogContent.slice(0 , 3);
    return (
      <Fragment>
        <Header homeLink="/" />

        {/* Start Slider Area   */}
        <div id="home">
          <div className="slider-wrapper">
            <SliderFour />
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div id="about">
          <div className="about-area about-position-top pb--120 bg_color--5">
            <About />
          </div>
        </div>
        {/* End About Area */}

        <div id="service" className="fix">
          <div className="service-area ptb--80  bg_image bg_image--3">
            <div className="container">
              <Service />
            </div>
          </div>
        </div>

        {/* Start Portfolio Area */}
        <div className="rn-portfolio-area bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30">
                  <h2 className="title">Proyectos realizados</h2>
                  <p>
                    Una pequeña muestra de los proyectos que hemos realizado.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper plr--30">
            <div className="row justify-content-around">
              <Portfolio
                item="9"
                column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover"
              />
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Team Area  */}
        <div id="team" className="fix">
          <div className="rn-team-area ptb--120 bg_color--5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                    <h2 className="title">Nuestro Equipo</h2>
                    <p>
                      Nuestro equipo está altamente calificado para prestar los
                      servicios que te ofrecemos.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
              </div>
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation bg_color--5 pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Brand />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Contact Page Area  */}
        {/* <div id="contact" className="fix">
                    <div className="rn-contact-page ptb--120 bg_color--1">
                        <ContactTwo />
                    </div>
                </div> */}
        {/* End Contact Page Area  */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5" id="contact">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Telefonos de contacto</h4>
                    <p>
                      <a href="tel:+573128372707">+57 312 837 2707</a>
                    </p>
                    <p>
                      <a href="tel:+573184043458">+57 318 404 3458</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Correo electrónico</h4>
                    <p>
                      <a href="mailto:jmontezuma@preztel.com">
                        jmontezuma@preztel.com
                      </a>
                    </p>
                    <p>
                      <a href="mailto:jgarcia@preztel.com">
                        jgarcia@preztel.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Ubicación</h4>
                    <p>
                      Cra 68 # 25-84 <br /> Cali, Valle del Cauca, Colombia.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </Fragment>
    );
  }
}
export default Preztel;
