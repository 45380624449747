import React, { Component } from "react";
import { FaHtml5, FaServer, FaCloudUploadAlt}  from "react-icons/fa";
import {MdSecurity} from "react-icons/md";

const ServiceList = [
    {
        icon: <FaHtml5 />,
        title: 'Desarrollo Web',
        description: 'Desarrollo de sitios web informativos y plataformas web funcionales.'
    },
    {
        icon: <FaCloudUploadAlt />,
        title: 'Internet de las Cosas',
        description: 'Desarrollo de proyectos de IoT tanto software como hardware.'
    },
    {
        icon: <FaServer />,
        title: 'Infraestructura de red',
        description: 'Diseño, configuración e instalación de infraestructura de redes siguendo los estándares de la norma ISO 27000.'
    },
    {
        icon: <MdSecurity />,
        title: 'Seguridad informática',
        description: 'Consultoría en seguridad informática teniendo en cuenta las normas ISO 27000, ISO 31000 e ISO 22301.'
    }
]

class Service extends Component{
    render(){
        let title = 'Servicios',
        description = 'En Preztel contamos con profesionales capacitados para brindarte un servicio con la mejor calidad.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="#contact"><span className="text">Contáctanos</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Service;
