import React, { Component } from "react";
import { FaLinkedinIn } from "react-icons/fa";

let TeamContent = [
  {
    images: "1",
    title: "Jesús David García",
    designation: "Ingeniero electrónico y de telecomunicaciones",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/jesus-david-garcia-acevedo",
      },
    ],
  },
  {
    images: "3",
    title: "Julián Montezuma",
    designation: "Ingeniero electrónico y de telecomunicaciones",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/jmontezuma/",
      },
    ],
  },
  {
    images: "4",
    title: "Juan Sebastián Ordóñez",
    designation: "Ingeniero electrónico y de telecomunicaciones",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/jsebastianordonez21/",
      },
    ],
  },
];

class Team extends Component {
  render() {
    const { column } = this.props;
    return (
      <React.Fragment>
        {TeamContent.map((value, i) => (
          <div className={`${column}`} key={i}>
            <div className="team">
              <div className="thumbnail">
                <img
                  src={`/assets/images/preztel/team-${value.images}.jpeg`}
                  alt="Blog Images"
                />
              </div>
              <div className="content">
                <h4 className="title">{value.title}</h4>
                <p className="designation">{value.designation}</p>
              </div>
              <ul className="social-icon">
                {value.socialNetwork.map((social, index) => (
                  <li key={index}>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`${social.url}`}
                    >
                      {social.icon}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default Team;
